
import { defineComponent } from "vue";
import LoggingCatalogue from "@/components/catalogues/LoggingCatalogue/LoggingCatalogue.vue";

export default defineComponent({
  components: { LoggingCatalogue },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
